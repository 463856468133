<!--地标产业联盟详情-->
<template>
  <div class="alianceDetailDiv">
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col class="rowSpaceAround">
        <!--   左侧菜单栏   -->
        <div class="leftDiv">
          <left-menu :menu-list="leftMenu" @menuActive="clickMenu"></left-menu>
        </div>
        <!--   右侧区域   -->
        <div class="rightDiv">
          <div class="storageDiv">
            <div v-for="(item, index) in imgList" :key="index" class="storage-card">
              <div class="storage-img">
                <img :src="item.url" alt/>
              </div>
              <div class="storage-title">
                {{item.allianceUnit}}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
import topPathList from '@/components/topPathList'
import leftMenu from '@/components/leftMenu'
export default {
  name: "allianceDetail",
  components: { topPathList, leftMenu },
  data() {
      return {
        pathObj: [
          {
            name: '首页',
            path: '/'
          },
          {
            name: '地标产业联盟',
            path: '/alliance/allianceDetail'
          },
        ],
        leftMenu: [],
        menuIndex: 0,
        imgList: [],//右侧数据
      }
  },
  mounted() {
    this.getLeftMenu()
  },
  methods: {
    clickMenu({ menu, index }) {
      this.menuIndex = index
      this.getLinkDetail()
    },

    //左侧菜单查询
    getLeftMenu() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/industrial/querytype",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let arr =  res.data.data
          //给左侧菜单赋值
          let newArr = []
          for (let i = 0; i < arr.length; i++) {
            newArr.push(arr[i].typeName)
            this.leftMenu = newArr.map(item => ({
              name: item
            }))
          }
          this.getLinkDetail()
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //接口调用
    getLinkDetail() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/industrial/queryUnion",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          //判断点击的是左侧的哪个菜单
          if (this.menuIndex == 0) {//仓储
            this.imgList = res.data.data.cc ? res.data.data.cc : []
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].url = this.imgList[i].allianceLogo
            }
          }
          if (this.menuIndex == 1) {//物流
            this.imgList = res.data.data.wl ? res.data.data.wl : []
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].url = this.imgList[i].allianceLogo
            }
          }
          if (this.menuIndex == 2) {//金融服务
            this.imgList = res.data.data.jrfw ? res.data.data.jrfw : []
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].url = this.imgList[i].allianceLogo
            }
          }
          if (this.menuIndex == 3) {//检测监测
            this.imgList = res.data.data.jcjc ? res.data.data.jcjc : []
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].url = this.imgList[i].allianceLogo
            }
          }
          if (this.menuIndex == 4) {//农资服务
            this.imgList = res.data.data.nzfw ? res.data.data.nzfw : []
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].url = this.imgList[i].allianceLogo
            }
          }
          if (this.menuIndex == 5) {//预制菜产业
            this.imgList = res.data.data.yzccy ? res.data.data.yzccy : []
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].url = this.imgList[i].allianceLogo
            }
          }
          if (this.menuIndex == 6) {//高校联盟
            this.imgList = res.data.data.gxlm ? res.data.data.gxlm : []
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].url = this.imgList[i].allianceLogo
            }
          }
          if (this.menuIndex == 7) {//平台联盟
            this.imgList = res.data.data.ptlm ? res.data.data.ptlm : []
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].url = this.imgList[i].allianceLogo
            }
          }
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    }
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .alianceDetailDiv {
    text-align: center;
  }
  .leftDiv {
    width: 15%;
  }
  .rightDiv {
    width: 82%;
    background: white;
    border-radius: 5px;
    .storageDiv {
      min-height: 557px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .storage-card {
        margin: 10px;
        background: rgba(255, 255, 255, 0.92);
        border-radius: 5px;
        box-sizing: border-box;
        img {
          width: 120px;
          height: 90px;
        }
        .storage-title {
          width: 120px;
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .alianceDetailDiv {
      text-align: center;
    }
    .leftDiv {
      width: 15%;
    }
    .rightDiv {
      width: 82%;
      background: white;
      border-radius: 5px;
      .storageDiv {
        min-height: 557px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        .storage-card {
          margin: 10px;
          background: rgba(255, 255, 255, 0.92);
          border-radius: 5px;
          box-sizing: border-box;
          img {
            width: 120px;
            height: 90px;
          }
          .storage-title {
            width: 120px;
          }
        }
      }
    }
  }
</style>
